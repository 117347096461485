<template>
  <div class="card w-25x flex-grow-1 flex-sm-grow-0 m-sm-auto">
    <div class="card-body p-sm-5 m-sm-3 flex-grow-0">
      <form @submit.prevent="handleLogin">
        <div class="mt-2 pt-1 mb-5 pb-2 text-center">
          <img
            src="/images/invertido-horizontal-alta.png"
            class="max-w-100 bg-dark p-5"
          />
        </div>
        <h1 class="mb-0 fs-3 text-center">Expedientes Medicos</h1>
        <div class="fs-exact-14 text-muted mt-2 pt-1 mb-5 pb-2 text-center">
          Capture sus credenciales para ingresar al sistema
        </div>
        <div class="mb-4">
          <label class="form-label">Correo Electrónico</label
          ><input
            type="email"
            class="form-control form-control-lg"
            required
            v-model="loginForm.username"
          />
        </div>
        <div class="mb-4">
          <label class="form-label">Contraseña</label
          ><input
            type="password"
            class="form-control form-control-lg"
            required
            v-model="loginForm.password"
          />
        </div>
        <div class="mb-4 row py-2 flex-wrap">
          <div class="col-auto me-auto"></div>
          <div class="col-auto d-flex align-items-center">
            <a href="#">¿Olvido su contraseña?</a>
          </div>
        </div>
        <div>
          <button type="submit" class="btn btn-primary btn-lg w-100">
            Ingresar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useAuth from "../composables/useAuth";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
export default {
  setup() {
    const router = useRouter();
    const { loginUser } = useAuth();
    const loginForm = ref({
      username: "",
      password: "",
    });

    const handleLogin = async () => {
      const result = await loginUser(loginForm.value);

      if (result.ok) {
        router.push({ name: "pacientes" });
      } else {
        Swal.fire("Atención", result.message, "error");
      }
    };

    return { loginForm, handleLogin };
  },
};
</script>

<style lang="scss" scoped>
</style>